/*------------------------------------*\
  panels
\*------------------------------------*/


// OPEN MENU



.panel {
  width: 100%;
  z-index: 1000;
  justify-content: space-between;
  padding: $spacing-sm;
  position: fixed;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: flex-start;
  transition: left .5s;
  left: -100vw;
  max-width: 800px;

  &.active {
    left: 0;
  }

  @include media('>=sm') {
    left: -60vw;
    width: 60vw;
  }

  @include media('>=md') {
    left: -40vw;
    width: 40vw;
  }

  &__inner {
    width: 100%;
    margin-right: $spacing-sm;

    a {
      color: white;
    }
  }
}

.panel-primary-navigation {
  background: $white;
  color: $black;

  .panel__list {
    width: 100%;
    margin-right: $spacing-sm;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    a {
      color: $black;
    }


    li {
      width: 100%;
      cursor: pointer;
      font-size: $font-size-md;
      // padding-left: $spacing-sm;
      line-height: 25px;
      border-top: 1px solid $black;
      padding-bottom: $spacing-sm;
      padding-top: $spacing-xs;

      @include media('<=sm') {
        display: block;
        width: 100%;
      }
    }

    .is-active {
      color: $lightgrey;
    }

    .primary-navigation__sublist {
      li {
        border-top: none;
        padding-top: $spacing-xs;
        padding-bottom: $spacing-xs;
        padding-left: $spacing-sm;
      }
    }
  }
}

.panel-details {
  overflow-y: auto;
  background: $dark-gray;
  color: $white;

  .panel {

    &__inner {
      padding-bottom: 31px;
    }

    &__title,
    &__text {
      border-top: 1px solid $white;
      padding-top: $spacing-sm;
      margin-bottom: $spacing-sm;
    }

    &__infos {
      @extend .ts-body;
      margin-bottom: $spacing-sm;

      h3 {
        border-top: 1px solid $white;
        padding-top: $spacing-xs;
      }

      p {
        padding-left: $spacing-sm;
        margin-bottom: $spacing-xs;
      }
    }
  }


  .icon__cross span:after,
  .icon__cross span:before {
    background-color: $white;
  }
}
