/*------------------------------------*\
  detail
\*------------------------------------*/

// @keyframes fadeIt {
//   0%   { opacity: 0; }
//   100% { opacity: 1; }
// }

// .main-project{
//     animation: fadeIt 0.2s ease;
//     -webkit-animation-fill-mode: forwards;
// }

// .main-text{
//   .index{
//     padding: $spacing-sm;
//     margin: 0 auto;
//     max-width: 820px;
//   }
// }

.body {
  img {
    display: block;
    margin: $spacing-md auto;
    height: 215px;
    width: auto;
    max-width: 100%
  }
}

.detail__inner {
  @extend %container;
}

.detail__text {
  max-width: $content-max-width;
}

.detail__title {
  display: none;
  @extend .ts-title;
  margin-bottom: $spacing-sm;

  &+.publication-date {
    margin-top: -$spacing-xs;
    margin-bottom: $spacing-sm;
  }
}

.detail__subtitle {
  @extend .ts-subtitle;
  margin-bottom: $spacing-sm;

  .detail__title+& {
    margin-top: -$spacing-sm;
  }
}

.detail__title+.detail__intro {
  margin-top: -$spacing-xs;
}

.detail__time {
  @extend .ts-subtitle;
  margin-bottom: $spacing-sm;

  .detail__title+& {
    margin-top: -$spacing-sm;
  }
}

.detail .gallery {
  margin-top: $spacing-lg;

  @include media('<sm') {
    margin-top: $spacing-lg;
  }
}

.detail__intro.body p {
  @extend .ts-subtitle;
}