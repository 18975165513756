.gallery--slideshow {
  width: 100%;
  height: 100vh;
  // padding: 62px $spacing-sm calc(62px * 2);
  // padding: 62px 0 124px 0;

  .flickity-prev-next-button {
    width: 24px;
    padding: 0;
    top: 62px;
    bottom: 124px;
    border-radius: 0;
    height: calc(100vh - 186px);
    transform: none;
    display: none;

    @include media('>=sm') {
      display: block;
    }
  }

  .flickity-button:disabled {
    display: none;
  }

  .flickity-prev-next-button.previous {
    left: $spacing-xs*1.5;
  }

  .flickity-prev-next-button.next {
    right: $spacing-xs*1.5;
  }
}

.gallery__item {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 31px;
  padding-bottom: 93px;
  padding-left: $spacing-sm;
  padding-right: $spacing-sm;

  @include media('>=sm') {
    padding-left: 62px;
    padding-right: 62px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-width: 1400px;
    opacity: 0;
    transition: 1s opacity;
  }

  &.is-selected {
    img {
      opacity: 1;
    }
  }
}

.gallery--hidden {
  display: none;
}
