/*------------------------------------*\
  index
\*------------------------------------*/

.index--grid{
  .index__title{
    display: none;
  }
  .index__content{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: $spacing-lg;
    .block {
      width: calc(100%/4);
      @include media('<md'){width: calc(100%/2);}
      padding-left: $spacing-sm;
      padding-right: $spacing-sm;
      margin-bottom: $spacing-md;
    }
    .block__link{
      text-decoration: none;
    }
    .block__media{
      @supports (object-fit: cover) {
        @include aspect-ratio(15, 10);
        position: relative;
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: contain;
          left: 0;
          top:0;
        }
      }
    }
    .block__text{
      padding: $spacing-sm;
      text-align: center;
    }
  }
}
