/*------------------------------------*\
  button
\*------------------------------------*/

.button {
  // reset
  background: none;
  border: none;
  padding: 0;
  // properties
  display: inline-block;
  @extend .ts-body;
  color: $base-color;
  text-decoration: none;
  padding-bottom: .3em;
  border-bottom: $border-width-sm solid $base-color;
}
