.index--home {
  .index {
    &__logo {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: normal;
      height: 100vh;
    }

    &__item {
      padding-left: $spacing-sm;
      padding-right: $spacing-sm;
      padding-top: 31px;
      padding-bottom: 93px;

      @include media('>=sm') {
        padding-left: 62px;
        padding-right: 62px;
      }


      &__link {
        display: block;
        height: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        max-width: 1200px;
      }
    }
  }
}

.index--text {
  .index {
    &__inner {
      padding: 0 $spacing-sm;
      margin-top: $spacing-sm;
      margin-bottom: 124px;

      @include media('>=sm') {
        width: calc(100vw / 1.5);
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &__title {
      display: none;
    }
  }

  .block_list {
    &:not(:first-child) {
      margin-top: $spacing-sm;
    }
  }

  .block__title {
    border-top: 1px solid;
    padding-top: $spacing-xs;
    margin-bottom: $spacing-xs;
  }
}
