/*------------------------------------*\
  gallery
\*------------------------------------*/

.gallery--basic {
  img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 90vh;
    height: auto;
  }
  .gallery__item + .gallery__item {
    margin-top: $spacing-sm;
  }
}
