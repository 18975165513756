/*------------------------------------*\
  global
\*------------------------------------*/

// .wrapper {
//   filter: grayscale(1);
//   transition: filter .25s;

//   &.loaded {
//     filter: grayscale(0);
//   }

//   &.unloaded {
//     filter: grayscale(1);
//   }
// }

// .wrapper {
//   // min-height: 100vh;
//   display: flex;
//   flex-direction: column;

//   @include media('<=md') {
//     min-height: inherit;
//   }

//   .footer {
//     margin-top: auto;
//   }
// }

%container {
  width: 100%;
  padding-left: $spacing-sm;
  padding-right: $spacing-sm;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}

%content-max-width {
  width: 100%;
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
}

%container-sm {
  width: 75vw;
  padding-left: $spacing-sm;
  padding-right: $spacing-sm;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}