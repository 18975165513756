/*------------------------------------*\
  #MODULES
\*------------------------------------*/

@import "_variables.scss";
@import "external/_flickity.scss";
@import "external/_include-media.scss";

@import "utils/_reset.scss";
@import "utils/_fonts.scss";
@import "utils/_mixins.scss";
@import "utils/_typography.scss";
@import "utils/_medias.scss";
@import "utils/_misc.scss";
@import "utils/_global.scss";
@import "utils/_animate.scss";


/*------------------------------------*\
  #EXTERNAL
\*------------------------------------*/

// @import "external/_include-media.scss";


/*------------------------------------*\
  #PARTIALS
\*------------------------------------*/

// components
@import "components/_header.scss";
@import "components/_panel.scss";
@import "components/_nav.scss";
@import "components/_button.scss";
@import "components/_taxonomy.scss";
@import "components/_gallery.scss";
@import "components/_footer.scss";
@import "components/_form.scss";
@import "components/_pagination.scss";
@import "components/_slideshow.scss";
// templates
@import "templates/_index.scss";
// @import "templates/_index-list.scss";
@import "templates/_index-grid.scss";
@import "templates/_detail.scss";
@import "templates/_categories.scss";
@import "templates/_year.scss";



/*------------------------------------*\
  #BASE-STYLES
\*------------------------------------*/

html {
  font-size: 14px;

  @include media('>=xs') {
    font-size: 16px;
  }

  @include media(">=sm") {
    font-size: 18px;
  }
}

body {
  @extend %font-body;
  background: $body-color;
  color: $base-color;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;

  ::-moz-selection,
  ::selection {
    background-color: rgba($base-color, .1);
    color: $base-color;
  }
}

li {
  list-style: none;
}


/*------------------------------------*\
  #PRINT
\*------------------------------------*/


@import "utils/_print-style.scss";

@media print {
  html {
    font-size: 14px;
  }

  @include print-style;
}
