@font-face {
  font-family: 'le-patin-helvete-italic';
  src: url('fonts/SP_Le-patin-helvete-italic.eot');
  src: url('fonts/OSP_Le-patin-helvete-italic.eot?#iefix') format('embedded-opentype'),
    url('fonts/OSP_Le-patin-helvete-italic.woff2') format('woff2'),
    url('fonts/OSP_Le-patin-helvete-italic.woff') format('woff'),
    url('fonts/OSP_Le-patin-helvete-italic.ttf') format('truetype'),
    url('fonts/OSP_Le-patin-helvete-italic.svg#OSP_Le-patin-helvete-italic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'le-patin-helvete';
  src: url('fonts/OSP_Le-patin-helvete.eot');
  src: url('fonts/OSP_Le-patin-helvete.eot?#iefix') format('embedded-opentype'),
    url('fonts/OSP_Le-patin-helvete.woff2') format('woff2'),
    url('fonts/OSP_Le-patin-helvete.woff') format('woff'),
    url('fonts/OSP_Le-patin-helvete.ttf') format('truetype'),
    url('fonts/OSP_Le-patin-helvete.svg#OSP_Le-patin-helvete') format('svg');
  font-weight: normal;
  font-style: normal;
}
