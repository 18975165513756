/*------------------------------------*\
  #COLORS
\*------------------------------------*/

// Main colour palette
$white : #FFFFFF;
$black : #000000;
$blue : #0000FF;
$gray : #ccc;
$lightgrey: #bbb;
$dark-gray : #888;

// Assign colours to variable roles
$body-color : $gray;
$base-color : $black;
$action : $black;
$action-response : $gray;



/*------------------------------------*\
  #BREAKPOINTS
\*------------------------------------*/

$breakpoints: (xs : 480px,
  sm : 768px,
  md : 1024px,
  lg : 1280px,
  xl : 1440px,
  xxl : 1920px);

$grid-gutter-width: 30px !default;



/*------------------------------------*\
  #FONTS
\*------------------------------------*/

%font-body {
  font-family: 'le-patin-helvete', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;

}


%font-body-italic {
  font-family: 'le-patin-helvete-italic', -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen-Sans,
    Ubuntu,
    Cantarell,
    "Helvetica Neue",
    sans-serif;
  font-style: italic;
}

%font-body-bold {
  font-family: 'le-patin-helvete', -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen-Sans,
    Ubuntu,
    Cantarell,
    "Helvetica Neue",
    sans-serif;
  font-weight: 700;
}

%font-body-bold-italic {
  font-family: 'le-patin-helvete-italic', -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen-Sans,
    Ubuntu,
    Cantarell,
    "Helvetica Neue",
    sans-serif;
  font-style: italic;
  font-weight: 700;
}



/*------------------------------------*\
    #TYPESIZE/SCALE
\*------------------------------------*/

$font-base : 1rem;
$line-height : 1.4;
$line-height-var : 1.2;

$font-size-sm : $font-base * 0.75; // 14
$font-size-md : $font-base * 1; // 18
$font-size-lg : $font-base * 1.25; // 22
$font-size-xl : $font-base * 2; // 28

$line-height-lg : $line-height * 1.25; // 22

%font-size-sm {
  font-size: $font-size-sm;
  line-height: $line-height;
}

%font-size-md {
  font-size: $font-size-md;
  line-height: $line-height;
}

%font-size-lg {
  font-size: $font-size-lg;
  line-height: $line-height;
}

%font-size-xl {
  font-size: $font-size-xl;
  line-height: $line-height-var;
}



/*------------------------------------*\
  #SPACING
\*------------------------------------*/

$spacing-base : 1rem;

$spacing-xxxl : $spacing-base * 6; // 120
$spacing-xxl : $spacing-base * 4; // 80
$spacing-xl : $spacing-base * 3; // 60
$spacing-lg : $spacing-base * 2.5; // 40
$spacing-md : $spacing-base * 1.5; // 30
$spacing-sm : $spacing-base * 1; // 20
$spacing-xs : $spacing-base * 0.5; // 10
$spacing-xxs : $spacing-base * 0.25; // 5



/*------------------------------------*\
  #BORDERS WIDTH
\*------------------------------------*/

$border-width-sm : 1px;
$border-width-md : $border-width-sm*3;


/*------------------------------------*\
  #MISC
\*------------------------------------*/

$content-max-width : 750px;