/*------------------------------------*\
  text-styles
\*------------------------------------*/


.ts-title {
  @extend %font-size-xl;
  @extend %font-body;
}

.ts-subtitle {
  @extend %font-size-lg;
  @extend %font-body;
}

.ts-subsubtitle {
  @extend %font-size-md;
  @extend %font-body-bold;
}

.ts-body {
  @extend %font-size-md;
  @extend %font-body;
}

.ts-small {
  @extend %font-size-sm;
  @extend %font-body;
}

.ts-label {
  @extend %font-size-sm;
  @extend %font-body;
  text-transform: uppercase;
  letter-spacing: .1em;
}

.ts-link {
  color: $base-color;
  text-decoration: none;

  &:hover {
    color: $action;
    text-decoration: none;
  }
}

.ts-link-nav {
  color: white;
  text-decoration: none;

  &:hover {
    color: $lightgrey;
    text-decoration: none;
  }
}

.ts-body-link {
  color: $base-color;
  text-decoration: underline;

  &:hover {
    color: $action;
    text-decoration: underline;
  }
}


/*------------------------------------*\
  title
\*------------------------------------*/

h1,
h2,
h3,
h4,
h5,
h6{
  font-weight: normal;
}

.body {

  h1,
  h2 {
    @extend .ts-body !optional;
  }

  h3 {
    @extend .ts-body !optional;
  }

  h4,
  h5,
  h6 {
    @extend .ts-body !optional;
  }

  h2,
  h3,
  h4,
  h5 {
    &:not(:first-child) {
      margin-top: $spacing-sm;
    }

    margin-bottom: $spacing-xs;
    // text-shadow: .05em .05em .2em rgba(142, 142, 142, .8);
  }
}



/*------------------------------------*\
  paragraph
\*------------------------------------*/

.body {
  @extend .ts-body !optional;

  p {
    @extend .ts-body !optional;
    margin-left: $spacing-sm;

    &:not(:last-child) {
      margin-bottom: $spacing-xs;
    }
  }
}



/*------------------------------------*\
  link
\*------------------------------------*/

.body a {
  @extend .ts-body-link;
}



/*------------------------------------*\
  bold italic
\*------------------------------------*/

.body {

  em,
  i {
    font-style: normal;
    @extend %font-body;
  }

  b,
  strong {
    @extend %font-body;
    font-weight: 400;
    text-shadow: .05em .05em .2em rgba(142, 142, 142, .8);
  }

  em>b,
  b>em,
  i>b,
  b>i,
  em>strong,
  strong>em,
  i>strong,
  strong>i {
    font-style: normal;
    @extend %font-body;
  }
}



/*------------------------------------*\
  blockquote
\*------------------------------------*/

.body blockquote {
  padding-left: 1em;
  border-left: $border-width-sm solid $base-color;
  margin-top: 1em;

  &>p {
    font-style: normal;
    @extend %font-body;
  }

  &>blockquote p {
    font-style: normal;
    @extend %font-body;
  }
}



/*------------------------------------*\
  definitions
\*------------------------------------*/

.body dl {
  @extend .ts-body !optional;

  dt {
    @extend %font-body;
    padding-bottom: .3em;

    &:first-of-type {
      padding-top: .6em;
      border-top: $border-width-sm solid $base-color;
    }
  }

  dd {
    padding-bottom: .6em;
    margin-bottom: .6em;
    border-bottom: $border-width-sm $base-color solid;
  }
}



/*------------------------------------*\
  list
\*------------------------------------*/

// ORDERED
.body ol {
  counter-reset: li;

  li {
    position: relative;
    padding-left: 1.2em;
    @extend .ts-body !optional;
    margin-bottom: .5em;

    &:before {
      content: counter(li);
      counter-increment: li;
      position: absolute;
      left: 0;
      @extend .ts-body !optional;
    }
  }

  ol>li:first-of-type {
    margin-top: .5em;
  }
}

// UNORDERED
.body ul {
  li {
    position: relative;
    padding-left: 0;
    @extend .ts-body !optional;
    margin-bottom: .5em;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      @extend .ts-body !optional;
      font-size: 1em;
    }
  }

  ol>li:first-of-type {
    margin-top: .5em;
  }
}



/*------------------------------------*\
  table
\*------------------------------------*/


.body table {
  width: 100%;
  margin-top: $spacing-sm;
  margin-bottom: $spacing-sm;

  thead {
    border-bottom: $border-width-sm solid $base-color;
  }

  th {
    text-align: left;
    @extend %font-body;
  }

  tbody tr {
    &:nth-child(odd) {
      background-color: rgba($base-color, .05);
    }
  }

  tfoot {
    border-top: $border-width-md solid $base-color;
  }

  th,
  td {
    padding: .5em 0;
  }

  @include media("<sm") {

    th,
    td {
      display: block;
    }
  }
}



/*------------------------------------*\
  spacing
\*------------------------------------*/

.body {

  ul,
  ol,
  .embed-container {
    margin-bottom: 1em;
  }
}



/*------------------------------------*\
  misc
\*------------------------------------*/

.body {

  ol,
  ul {
    list-style: none;
  }

  sub,
  sup {
    font-size: .75em;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sup {
    top: -0.5em;
  }

  sub {
    bottom: -0.25em;
  }

  small {
    @extend %font-size-sm;
  }

  abbr {
    border-bottom: $border-width-sm dotted $base-color;
    cursor: help;
  }

  q,
  cite {
    &:before {
      content: '"';
    }

    &:after {
      content: '"';
    }
  }

  mark {
    background-color: rgba($base-color, .6);
    color: $base-color;
  }

  code,
  samp,
  pre {
    font-family: monospace;
    @extend %font-size-sm;
  }

  hr {
    // margin-top: $spacing-lg;
    margin-bottom: $spacing-md;
  }

}

hr {
  background: $base-color;
  height: $border-width-sm;
  border: none;
}

address {
  font-style: normal;

  h3 {
    font-weight: 400;
  }

  h3,
  p {
    margin-bottom: $spacing-xs;
  }

  p:last-of-type {
    margin-bottom: 0;
  }
}
