/*------------------------------------*\
  #MODULES
\*------------------------------------*/
/*------------------------------------*\
  #COLORS
\*------------------------------------*/
/*------------------------------------*\
  #BREAKPOINTS
\*------------------------------------*/
/*------------------------------------*\
  #FONTS
\*------------------------------------*/
/* line 40, resources/assets/front/css/_variables.scss */
.ts-title, .detail__title, .ts-subtitle, .detail__subtitle, .detail__time, .detail__intro.body p, .ts-body, .body h1,
.body h2, .body h3, .body h4,
.body h5,
.body h6, .body, .body p, .body dl, .body ol li, .body ol li:before, .body ul li, .body ul li:before, .panel-details .panel__infos, .logo__link,
.nav__link, .button, .pagination__link, .form label, .form .input-group input, .ts-small, .ts-label, .taxonomy__link, .body em,
.body i, .body b,
.body strong, .body em > b,
.body b > em,
.body i > b,
.body b > i,
.body em > strong,
.body strong > em,
.body i > strong,
.body strong > i, .body blockquote > p, .body blockquote > blockquote p, .body dl dt, .body table th, body {
  font-family: 'le-patin-helvete', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif; }

/* line 59, resources/assets/front/css/_variables.scss */
.ts-subsubtitle, .nav--subnav .nav__link.active {
  font-family: 'le-patin-helvete', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 700; }

/*------------------------------------*\
    #TYPESIZE/SCALE
\*------------------------------------*/
/* line 103, resources/assets/front/css/_variables.scss */
.ts-small, .ts-label, .taxonomy__link, .body small, .body code,
.body samp,
.body pre, figcaption {
  font-size: 0.75rem;
  line-height: 1.4; }

/* line 108, resources/assets/front/css/_variables.scss */
.ts-subsubtitle, .ts-body, .body h1,
.body h2, .body h3, .body h4,
.body h5,
.body h6, .body, .body p, .body dl, .body ol li, .body ol li:before, .body ul li, .body ul li:before, .panel-details .panel__infos, .logo__link,
.nav__link, .button, .pagination__link, .form label, .form .input-group input {
  font-size: 1rem;
  line-height: 1.4; }

/* line 113, resources/assets/front/css/_variables.scss */
.ts-subtitle, .detail__subtitle, .detail__time, .detail__intro.body p {
  font-size: 1.25rem;
  line-height: 1.4; }

/* line 118, resources/assets/front/css/_variables.scss */
.ts-title, .detail__title {
  font-size: 2rem;
  line-height: 1.2; }

/*------------------------------------*\
  #SPACING
\*------------------------------------*/
/*------------------------------------*\
  #BORDERS WIDTH
\*------------------------------------*/
/*------------------------------------*\
  #MISC
\*------------------------------------*/
/*! Flickity v2.2.1
https://flickity.metafizzy.co
---------------------------------------------- */
/* line 5, resources/assets/front/css/external/_flickity.scss */
.flickity-enabled {
  position: relative; }

/* line 9, resources/assets/front/css/external/_flickity.scss */
.flickity-enabled:focus {
  outline: none; }

/* line 11, resources/assets/front/css/external/_flickity.scss */
.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

/* line 17, resources/assets/front/css/external/_flickity.scss */
.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

/* draggable */
/* line 25, resources/assets/front/css/external/_flickity.scss */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* line 33, resources/assets/front/css/external/_flickity.scss */
.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }

/* line 39, resources/assets/front/css/external/_flickity.scss */
.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

/* ---- flickity-button ---- */
/* line 46, resources/assets/front/css/external/_flickity.scss */
.flickity-button {
  position: absolute;
  border: none;
  color: #333; }

/* line 52, resources/assets/front/css/external/_flickity.scss */
.flickity-button:hover {
  cursor: pointer; }

/* line 56, resources/assets/front/css/external/_flickity.scss */
.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F; }

/* line 61, resources/assets/front/css/external/_flickity.scss */
.flickity-button:active {
  opacity: 0.6; }

/* line 65, resources/assets/front/css/external/_flickity.scss */
.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none; }

/* line 72, resources/assets/front/css/external/_flickity.scss */
.flickity-button-icon {
  fill: currentColor;
  display: none; }

/* ---- previous/next buttons ---- */
/* line 79, resources/assets/front/css/external/_flickity.scss */
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: transparent;
  /* vertically center */
  transform: translateY(-50%); }

/* line 89, resources/assets/front/css/external/_flickity.scss */
.flickity-prev-next-button.previous {
  left: 10px; }

/* line 90, resources/assets/front/css/external/_flickity.scss */
.flickity-prev-next-button.next {
  right: 10px; }

/* right to left */
/* line 92, resources/assets/front/css/external/_flickity.scss */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

/* line 96, resources/assets/front/css/external/_flickity.scss */
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

/* line 101, resources/assets/front/css/external/_flickity.scss */
.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

/* ---- page dots ---- */
/* line 111, resources/assets/front/css/external/_flickity.scss */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

/* line 122, resources/assets/front/css/external/_flickity.scss */
.flickity-rtl .flickity-page-dots {
  direction: rtl; }

/* line 124, resources/assets/front/css/external/_flickity.scss */
.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer; }

/* line 135, resources/assets/front/css/external/_flickity.scss */
.flickity-page-dots .dot.is-selected {
  opacity: 1; }

/* line 142, resources/assets/front/css/external/_flickity.scss */
.flickity-prev-next-button:after {
  color: black;
  content: '';
  width: 20px;
  height: 20px;
  border-bottom: 2px solid currentcolor;
  border-right: 2px solid currentcolor;
  position: absolute; }

/* line 153, resources/assets/front/css/external/_flickity.scss */
.flickity-prev-next-button.previous:after {
  left: 12px;
  transform: rotate(135deg); }

/* line 159, resources/assets/front/css/external/_flickity.scss */
.flickity-prev-next-button.next:after {
  right: 12px;
  transform: rotate(-45deg); }

/*------------------------------------*\
  reset
\*------------------------------------*/
/* line 5, resources/assets/front/css/utils/_reset.scss */
*,
*:before,
*:after {
  box-sizing: border-box; }

/* line 11, resources/assets/front/css/utils/_reset.scss */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline; }

/* line 99, resources/assets/front/css/utils/_reset.scss */
table {
  border-collapse: collapse;
  border-spacing: 0; }

/* line 104, resources/assets/front/css/utils/_reset.scss */
caption,
th,
td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle; }

/* line 112, resources/assets/front/css/utils/_reset.scss */
q,
blockquote {
  quotes: none; }

/* line 117, resources/assets/front/css/utils/_reset.scss */
q:before,
q:after,
blockquote:before,
blockquote:after {
  content: "";
  content: none; }

/* line 125, resources/assets/front/css/utils/_reset.scss */
a img {
  border: none; }

/* line 129, resources/assets/front/css/utils/_reset.scss */
a {
  outline: 0; }

/* line 133, resources/assets/front/css/utils/_reset.scss */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary {
  display: block; }

/* line 148, resources/assets/front/css/utils/_reset.scss */
button,
input {
  line-height: normal; }

/* line 153, resources/assets/front/css/utils/_reset.scss */
button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle; }

/* line 163, resources/assets/front/css/utils/_reset.scss */
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  *overflow: visible; }

/* line 171, resources/assets/front/css/utils/_reset.scss */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/* line 177, resources/assets/front/css/utils/_reset.scss */
textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical; }

/* line 183, resources/assets/front/css/utils/_reset.scss */
a {
  color: inherit; }

@font-face {
  font-family: 'le-patin-helvete-italic';
  src: url("fonts/SP_Le-patin-helvete-italic.eot");
  src: url("fonts/OSP_Le-patin-helvete-italic.eot?#iefix") format("embedded-opentype"), url("fonts/OSP_Le-patin-helvete-italic.woff2") format("woff2"), url("fonts/OSP_Le-patin-helvete-italic.woff") format("woff"), url("fonts/OSP_Le-patin-helvete-italic.ttf") format("truetype"), url("fonts/OSP_Le-patin-helvete-italic.svg#OSP_Le-patin-helvete-italic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'le-patin-helvete';
  src: url("fonts/OSP_Le-patin-helvete.eot");
  src: url("fonts/OSP_Le-patin-helvete.eot?#iefix") format("embedded-opentype"), url("fonts/OSP_Le-patin-helvete.woff2") format("woff2"), url("fonts/OSP_Le-patin-helvete.woff") format("woff"), url("fonts/OSP_Le-patin-helvete.ttf") format("truetype"), url("fonts/OSP_Le-patin-helvete.svg#OSP_Le-patin-helvete") format("svg");
  font-weight: normal;
  font-style: normal; }

/*------------------------------------*\
  mixins
\*------------------------------------*/
/*------------------------------------*\
  text-styles
\*------------------------------------*/
/* line 31, resources/assets/front/css/utils/_typography.scss */
.ts-label, .taxonomy__link {
  text-transform: uppercase;
  letter-spacing: .1em; }

/* line 38, resources/assets/front/css/utils/_typography.scss */
.ts-link, .logo__link,
.nav__link {
  color: #000000;
  text-decoration: none; }
  /* line 42, resources/assets/front/css/utils/_typography.scss */
  .ts-link:hover, .logo__link:hover,
  .nav__link:hover {
    color: #000000;
    text-decoration: none; }

/* line 48, resources/assets/front/css/utils/_typography.scss */
.ts-link-nav {
  color: white;
  text-decoration: none; }
  /* line 52, resources/assets/front/css/utils/_typography.scss */
  .ts-link-nav:hover {
    color: #bbb;
    text-decoration: none; }

/* line 58, resources/assets/front/css/utils/_typography.scss */
.ts-body-link, .body a {
  color: #000000;
  text-decoration: underline; }
  /* line 62, resources/assets/front/css/utils/_typography.scss */
  .ts-body-link:hover, .body a:hover {
    color: #000000;
    text-decoration: underline; }

/*------------------------------------*\
  title
\*------------------------------------*/
/* line 73, resources/assets/front/css/utils/_typography.scss */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal; }

/* line 99, resources/assets/front/css/utils/_typography.scss */
.body h2,
.body h3,
.body h4,
.body h5 {
  margin-bottom: 0.5rem; }
  /* line 103, resources/assets/front/css/utils/_typography.scss */
  .body h2:not(:first-child),
  .body h3:not(:first-child),
  .body h4:not(:first-child),
  .body h5:not(:first-child) {
    margin-top: 1rem; }

/*------------------------------------*\
  paragraph
\*------------------------------------*/
/* line 121, resources/assets/front/css/utils/_typography.scss */
.body p {
  margin-left: 1rem; }
  /* line 125, resources/assets/front/css/utils/_typography.scss */
  .body p:not(:last-child) {
    margin-bottom: 0.5rem; }

/*------------------------------------*\
  link
\*------------------------------------*/
/*------------------------------------*\
  bold italic
\*------------------------------------*/
/* line 149, resources/assets/front/css/utils/_typography.scss */
.body em,
.body i {
  font-style: normal; }

/* line 155, resources/assets/front/css/utils/_typography.scss */
.body b,
.body strong {
  font-weight: 400;
  text-shadow: 0.05em 0.05em 0.2em rgba(142, 142, 142, 0.8); }

/* line 162, resources/assets/front/css/utils/_typography.scss */
.body em > b,
.body b > em,
.body i > b,
.body b > i,
.body em > strong,
.body strong > em,
.body i > strong,
.body strong > i {
  font-style: normal; }

/*------------------------------------*\
  blockquote
\*------------------------------------*/
/* line 181, resources/assets/front/css/utils/_typography.scss */
.body blockquote {
  padding-left: 1em;
  border-left: 1px solid #000000;
  margin-top: 1em; }
  /* line 186, resources/assets/front/css/utils/_typography.scss */
  .body blockquote > p {
    font-style: normal; }
  /* line 191, resources/assets/front/css/utils/_typography.scss */
  .body blockquote > blockquote p {
    font-style: normal; }

/*------------------------------------*\
  definitions
\*------------------------------------*/
/* line 206, resources/assets/front/css/utils/_typography.scss */
.body dl dt {
  padding-bottom: .3em; }
  /* line 210, resources/assets/front/css/utils/_typography.scss */
  .body dl dt:first-of-type {
    padding-top: .6em;
    border-top: 1px solid #000000; }

/* line 216, resources/assets/front/css/utils/_typography.scss */
.body dl dd {
  padding-bottom: .6em;
  margin-bottom: .6em;
  border-bottom: 1px #000000 solid; }

/*------------------------------------*\
  list
\*------------------------------------*/
/* line 230, resources/assets/front/css/utils/_typography.scss */
.body ol {
  counter-reset: li; }
  /* line 233, resources/assets/front/css/utils/_typography.scss */
  .body ol li {
    position: relative;
    padding-left: 1.2em;
    margin-bottom: .5em; }
    /* line 239, resources/assets/front/css/utils/_typography.scss */
    .body ol li:before {
      content: counter(li);
      counter-increment: li;
      position: absolute;
      left: 0; }
  /* line 248, resources/assets/front/css/utils/_typography.scss */
  .body ol ol > li:first-of-type {
    margin-top: .5em; }

/* line 255, resources/assets/front/css/utils/_typography.scss */
.body ul li {
  position: relative;
  padding-left: 0;
  margin-bottom: .5em; }
  /* line 261, resources/assets/front/css/utils/_typography.scss */
  .body ul li:before {
    content: "";
    position: absolute;
    left: 0;
    font-size: 1em; }

/* line 270, resources/assets/front/css/utils/_typography.scss */
.body ul ol > li:first-of-type {
  margin-top: .5em; }

/*------------------------------------*\
  table
\*------------------------------------*/
/* line 282, resources/assets/front/css/utils/_typography.scss */
.body table {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem; }
  /* line 287, resources/assets/front/css/utils/_typography.scss */
  .body table thead {
    border-bottom: 1px solid #000000; }
  /* line 291, resources/assets/front/css/utils/_typography.scss */
  .body table th {
    text-align: left; }
  /* line 297, resources/assets/front/css/utils/_typography.scss */
  .body table tbody tr:nth-child(odd) {
    background-color: rgba(0, 0, 0, 0.05); }
  /* line 302, resources/assets/front/css/utils/_typography.scss */
  .body table tfoot {
    border-top: 3px solid #000000; }
  /* line 306, resources/assets/front/css/utils/_typography.scss */
  .body table th,
  .body table td {
    padding: .5em 0; }
  @media (max-width: 767px) {
    /* line 313, resources/assets/front/css/utils/_typography.scss */
    .body table th,
    .body table td {
      display: block; } }

/*------------------------------------*\
  spacing
\*------------------------------------*/
/* line 328, resources/assets/front/css/utils/_typography.scss */
.body ul,
.body ol,
.body .embed-container {
  margin-bottom: 1em; }

/*------------------------------------*\
  misc
\*------------------------------------*/
/* line 343, resources/assets/front/css/utils/_typography.scss */
.body ol,
.body ul {
  list-style: none; }

/* line 348, resources/assets/front/css/utils/_typography.scss */
.body sub,
.body sup {
  font-size: .75em;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

/* line 356, resources/assets/front/css/utils/_typography.scss */
.body sup {
  top: -0.5em; }

/* line 360, resources/assets/front/css/utils/_typography.scss */
.body sub {
  bottom: -0.25em; }

/* line 368, resources/assets/front/css/utils/_typography.scss */
.body abbr {
  border-bottom: 1px dotted #000000;
  cursor: help; }

/* line 375, resources/assets/front/css/utils/_typography.scss */
.body q:before,
.body cite:before {
  content: '"'; }

/* line 379, resources/assets/front/css/utils/_typography.scss */
.body q:after,
.body cite:after {
  content: '"'; }

/* line 384, resources/assets/front/css/utils/_typography.scss */
.body mark {
  background-color: rgba(0, 0, 0, 0.6);
  color: #000000; }

/* line 389, resources/assets/front/css/utils/_typography.scss */
.body code,
.body samp,
.body pre {
  font-family: monospace; }

/* line 396, resources/assets/front/css/utils/_typography.scss */
.body hr {
  margin-bottom: 1.5rem; }

/* line 403, resources/assets/front/css/utils/_typography.scss */
hr {
  background: #000000;
  height: 1px;
  border: none; }

/* line 409, resources/assets/front/css/utils/_typography.scss */
address {
  font-style: normal; }
  /* line 412, resources/assets/front/css/utils/_typography.scss */
  address h3 {
    font-weight: 400; }
  /* line 416, resources/assets/front/css/utils/_typography.scss */
  address h3,
  address p {
    margin-bottom: 0.5rem; }
  /* line 421, resources/assets/front/css/utils/_typography.scss */
  address p:last-of-type {
    margin-bottom: 0; }

/*------------------------------------*\
  medias
\*------------------------------------*/
/* line 6, resources/assets/front/css/utils/_medias.scss */
img,
video,
object {
  max-width: 100%;
  height: auto; }

/* line 13, resources/assets/front/css/utils/_medias.scss */
img {
  display: block; }

/* line 17, resources/assets/front/css/utils/_medias.scss */
img.image-lazy {
  max-width: 100%;
  width: auto;
  height: auto;
  margin: auto; }
  /* line 23, resources/assets/front/css/utils/_medias.scss */
  img.image-lazy:not(.lazyloaded) {
    opacity: 0;
    transition: opacity 1s ease-in-out; }
  /* line 28, resources/assets/front/css/utils/_medias.scss */
  img.image-lazy.lazyloaded {
    opacity: 1;
    transition: opacity 1s ease-in-out; }

/* line 34, resources/assets/front/css/utils/_medias.scss */
figcaption {
  margin-top: 0.5rem; }

/* line 39, resources/assets/front/css/utils/_medias.scss */
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%; }

/* line 47, resources/assets/front/css/utils/_medias.scss */
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

/*------------------------------------*\
  misc
\*------------------------------------*/
/* line 6, resources/assets/front/css/utils/_misc.scss */
.hidden {
  display: none !important; }

@media (max-width: 767px) {
  /* line 10, resources/assets/front/css/utils/_misc.scss */
  .hidden-small {
    display: none !important; } }

@media (min-width: 768px) {
  /* line 15, resources/assets/front/css/utils/_misc.scss */
  .hidden-large {
    display: none !important; } }

/* line 21, resources/assets/front/css/utils/_misc.scss */
.nav__list {
  list-style: none; }

/* line 29, resources/assets/front/css/utils/_mixins.scss */
.clear::after {
  display: block;
  content: "";
  clear: both; }

/*------------------------------------*\
  global
\*------------------------------------*/
/* line 32, resources/assets/front/css/utils/_global.scss */
.nav--subnav .nav__list, .footer, .detail__inner {
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto; }

/* line 48, resources/assets/front/css/utils/_global.scss */
.categories, .year {
  width: 75vw;
  padding-left: 1rem;
  padding-right: 1rem;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto; }

/*------------------------------------*\
  #EXTERNAL
\*------------------------------------*/
/*------------------------------------*\
  #PARTIALS
\*------------------------------------*/
/*------------------------------------*\
  header
\*------------------------------------*/
/* line 5, resources/assets/front/css/components/_header.scss */
.header {
  height: 62px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  border-top: 1px solid #000000;
  background: #ccc; }
  /* line 15, resources/assets/front/css/components/_header.scss */
  .header .page-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center; }

/* line 25, resources/assets/front/css/components/_header.scss */
.main-index-projects .header_main {
  background: #ccc; }

/* line 33, resources/assets/front/css/components/_header.scss */
.header_main .header__inner {
  justify-content: space-between; }

/* line 37, resources/assets/front/css/components/_header.scss */
.header_main .icon {
  justify-content: flex-start; }

/* line 41, resources/assets/front/css/components/_header.scss */
.header_main .secondary-nav {
  text-align: right;
  justify-content: flex-end; }

/* line 47, resources/assets/front/css/components/_header.scss */
.header__inner {
  padding: 1rem;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%; }

/* line 55, resources/assets/front/css/components/_header.scss */
a.show {
  display: block; }

/* line 59, resources/assets/front/css/components/_header.scss */
.show {
  display: block; }

/* line 63, resources/assets/front/css/components/_header.scss */
.show-flex {
  display: flex !important; }

/* line 67, resources/assets/front/css/components/_header.scss */
.header-details {
  z-index: 3;
  top: 0vh;
  border-top: none;
  background: none; }
  /* line 73, resources/assets/front/css/components/_header.scss */
  .header-details .header__inner {
    justify-content: space-between; }

/* line 80, resources/assets/front/css/components/_header.scss */
.icon {
  display: flex;
  align-items: center;
  max-height: 25px;
  height: 100%;
  cursor: pointer; }

/* line 89, resources/assets/front/css/components/_header.scss */
.icon__menu {
  width: 35px;
  cursor: pointer;
  height: 2px;
  background: #000000; }
  /* line 98, resources/assets/front/css/components/_header.scss */
  .icon__menu span:before, .icon__menu span:after {
    position: relative;
    display: block;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #000000;
    content: ""; }
  /* line 109, resources/assets/front/css/components/_header.scss */
  .icon__menu span:after {
    bottom: -8px; }
  /* line 113, resources/assets/front/css/components/_header.scss */
  .icon__menu span:before {
    top: -10px; }

/* line 119, resources/assets/front/css/components/_header.scss */
.icon__cross {
  width: 35px;
  height: 35px;
  color: #FFFFFF; }
  /* line 124, resources/assets/front/css/components/_header.scss */
  .icon__cross span {
    display: block;
    position: relative; }
    /* line 128, resources/assets/front/css/components/_header.scss */
    .icon__cross span:before, .icon__cross span:after {
      position: absolute;
      display: block;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #000000;
      color: #000000;
      content: "";
      top: 15px; }
    /* line 141, resources/assets/front/css/components/_header.scss */
    .icon__cross span:after {
      transform: rotate(135deg); }
    /* line 145, resources/assets/front/css/components/_header.scss */
    .icon__cross span:before {
      transform: rotate(45deg); }

/* line 151, resources/assets/front/css/components/_header.scss */
.icon__info {
  width: 35px;
  height: 35px;
  color: #FFFFFF;
  cursor: pointer; }
  /* line 157, resources/assets/front/css/components/_header.scss */
  .icon__info span {
    display: block;
    position: relative; }
    /* line 161, resources/assets/front/css/components/_header.scss */
    .icon__info span:before, .icon__info span:after {
      position: absolute;
      display: block;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #000000;
      color: #000000;
      content: "";
      top: 15px; }
    /* line 174, resources/assets/front/css/components/_header.scss */
    .icon__info span:before {
      transform: rotate(90deg); }

/* line 181, resources/assets/front/css/components/_header.scss */
.icon__back_to_top {
  display: none; }

/* line 185, resources/assets/front/css/components/_header.scss */
.main-home + .header,
.main-project + .header {
  background: transparent; }

/*------------------------------------*\
  panels
\*------------------------------------*/
/* line 10, resources/assets/front/css/components/_panel.scss */
.panel {
  width: 100%;
  z-index: 1000;
  justify-content: space-between;
  padding: 1rem;
  position: fixed;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: flex-start;
  transition: left .5s;
  left: -100vw;
  max-width: 800px; }
  /* line 24, resources/assets/front/css/components/_panel.scss */
  .panel.active {
    left: 0; }
  @media (min-width: 768px) {
    /* line 10, resources/assets/front/css/components/_panel.scss */
    .panel {
      left: -60vw;
      width: 60vw; } }
  @media (min-width: 1024px) {
    /* line 10, resources/assets/front/css/components/_panel.scss */
    .panel {
      left: -40vw;
      width: 40vw; } }
  /* line 38, resources/assets/front/css/components/_panel.scss */
  .panel__inner {
    width: 100%;
    margin-right: 1rem; }
    /* line 42, resources/assets/front/css/components/_panel.scss */
    .panel__inner a {
      color: white; }

/* line 48, resources/assets/front/css/components/_panel.scss */
.panel-primary-navigation {
  background: #FFFFFF;
  color: #000000; }
  /* line 52, resources/assets/front/css/components/_panel.scss */
  .panel-primary-navigation .panel__list {
    width: 100%;
    margin-right: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    /* line 59, resources/assets/front/css/components/_panel.scss */
    .panel-primary-navigation .panel__list a {
      color: #000000; }
    /* line 64, resources/assets/front/css/components/_panel.scss */
    .panel-primary-navigation .panel__list li {
      width: 100%;
      cursor: pointer;
      font-size: 1rem;
      line-height: 25px;
      border-top: 1px solid #000000;
      padding-bottom: 1rem;
      padding-top: 0.5rem; }
      @media (max-width: 768px) {
        /* line 64, resources/assets/front/css/components/_panel.scss */
        .panel-primary-navigation .panel__list li {
          display: block;
          width: 100%; } }
    /* line 80, resources/assets/front/css/components/_panel.scss */
    .panel-primary-navigation .panel__list .is-active {
      color: #bbb; }
    /* line 85, resources/assets/front/css/components/_panel.scss */
    .panel-primary-navigation .panel__list .primary-navigation__sublist li {
      border-top: none;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-left: 1rem; }

/* line 95, resources/assets/front/css/components/_panel.scss */
.panel-details {
  overflow-y: auto;
  background: #888;
  color: #FFFFFF; }
  /* line 102, resources/assets/front/css/components/_panel.scss */
  .panel-details .panel__inner {
    padding-bottom: 31px; }
  /* line 106, resources/assets/front/css/components/_panel.scss */
  .panel-details .panel__title, .panel-details .panel__text {
    border-top: 1px solid #FFFFFF;
    padding-top: 1rem;
    margin-bottom: 1rem; }
  /* line 113, resources/assets/front/css/components/_panel.scss */
  .panel-details .panel__infos {
    margin-bottom: 1rem; }
    /* line 117, resources/assets/front/css/components/_panel.scss */
    .panel-details .panel__infos h3 {
      border-top: 1px solid #FFFFFF;
      padding-top: 0.5rem; }
    /* line 122, resources/assets/front/css/components/_panel.scss */
    .panel-details .panel__infos p {
      padding-left: 1rem;
      margin-bottom: 0.5rem; }
  /* line 130, resources/assets/front/css/components/_panel.scss */
  .panel-details .icon__cross span:after,
  .panel-details .icon__cross span:before {
    background-color: #FFFFFF; }

/*------------------------------------*\
  nav
\*------------------------------------*/
@media (min-width: 768px) {
  /* line 6, resources/assets/front/css/components/_nav.scss */
  .nav__item {
    display: inline-block; }
    /* line 10, resources/assets/front/css/components/_nav.scss */
    .nav:not(.nav--lang) .nav__item:not(:last-child) {
      margin-right: 1rem; } }

/* line 16, resources/assets/front/css/components/_nav.scss */
.logo__link,
.nav__link {
  display: block;
  color: #000000; }

@media (max-width: 767px) {
  /* line 27, resources/assets/front/css/components/_nav.scss */
  .nav__link {
    padding: 0.25rem 0; } }

/* line 35, resources/assets/front/css/components/_nav.scss */
.nav--subnav {
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: #ccc;
  border-bottom: 1px solid #000000; }
  /* line 42, resources/assets/front/css/components/_nav.scss */
  .nav--subnav .nav__list {
    padding-top: 1rem;
    padding-bottom: 1rem; }

/* line 53, resources/assets/front/css/components/_nav.scss */
.nav--subnav .nav__list {
  width: 100%;
  overflow-x: auto;
  display: flex;
  flex-wrap: nowrap; }
  /* line 59, resources/assets/front/css/components/_nav.scss */
  .nav--subnav .nav__list .nav__item {
    padding-right: 1rem; }
  /* line 63, resources/assets/front/css/components/_nav.scss */
  .nav--subnav .nav__list .nav__item {
    flex-shrink: 0; }

/*------------------------------------*\
  button
\*------------------------------------*/
/* line 5, resources/assets/front/css/components/_button.scss */
.button, .pagination__link {
  background: none;
  border: none;
  padding: 0;
  display: inline-block;
  color: #000000;
  text-decoration: none;
  padding-bottom: .3em;
  border-bottom: 1px solid #000000; }

/*------------------------------------*\
  taxonomy
\*------------------------------------*/
/* line 5, resources/assets/front/css/components/_taxonomy.scss */
.taxonomy {
  list-style: none;
  display: flex;
  flex-wrap: wrap; }

/* line 10, resources/assets/front/css/components/_taxonomy.scss */
.taxonomy__list {
  list-style: none; }

/* line 13, resources/assets/front/css/components/_taxonomy.scss */
.taxonomy__item {
  margin-right: 0.25rem;
  margin-bottom: 0.5rem;
  display: inline-block; }

/* line 18, resources/assets/front/css/components/_taxonomy.scss */
.taxonomy__link {
  display: block;
  background-color: #000000;
  color: #FFFFFF;
  text-decoration: none;
  padding: .2em .5em;
  border-radius: 3px;
  border: 1px solid transparent; }
  /* line 27, resources/assets/front/css/components/_taxonomy.scss */
  .taxonomy__link:hover {
    background-color: #ccc !important;
    color: #000000;
    border-color: #ccc; }

/*------------------------------------*\
  gallery
\*------------------------------------*/
/* line 6, resources/assets/front/css/components/_gallery.scss */
.gallery--basic img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 90vh;
  height: auto; }

/* line 13, resources/assets/front/css/components/_gallery.scss */
.gallery--basic .gallery__item + .gallery__item {
  margin-top: 1rem; }

/*------------------------------------*\
  footer
\*------------------------------------*/
/* line 8, resources/assets/front/css/components/_footer.scss */
.footer__inner {
  width: calc(100% + 15px);
  margin-left: -15px;
  padding-top: 3rem;
  padding-bottom: 1.5rem; }
  @media (min-width: 768px) {
    /* line 8, resources/assets/front/css/components/_footer.scss */
    .footer__inner {
      display: flex; } }

/* line 17, resources/assets/front/css/components/_footer.scss */
.footer__section {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 1.5rem;
  width: 100%; }
  @media (min-width: 768px) {
    /* line 17, resources/assets/front/css/components/_footer.scss */
    .footer__section {
      width: calc(100%/3); } }

/*------------------------------------*\
  form
\*------------------------------------*/
/* line 7, resources/assets/front/css/components/_form.scss */
.form label {
  display: block;
  margin-bottom: 0.5rem; }

/* line 12, resources/assets/front/css/components/_form.scss */
.form .input-group {
  position: relative;
  border: 1px solid #ccc;
  width: 750px;
  max-width: 100%; }
  /* line 17, resources/assets/front/css/components/_form.scss */
  .form .input-group input,
  .form .input-group button {
    border: none;
    height: 45px; }
  /* line 22, resources/assets/front/css/components/_form.scss */
  .form .input-group input {
    position: relative;
    z-index: 1;
    width: 100%;
    padding: 0.5rem 0.5rem; }
  /* line 29, resources/assets/front/css/components/_form.scss */
  .form .input-group button {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    font-size: 0;
    width: 40px;
    background: url("images/icon-check.svg") no-repeat center center; }
    /* line 37, resources/assets/front/css/components/_form.scss */
    .form .input-group button:hover {
      opacity: .5; }

/* line 44, resources/assets/front/css/components/_form.scss */
.no-touchevents .form button:hover {
  opacity: .5; }

/*------------------------------------*\
  pagination
\*------------------------------------*/
/* line 5, resources/assets/front/css/components/_pagination.scss */
.pagination {
  width: 100%;
  margin-top: 2.5rem; }
  @media (min-width: 768px) {
    /* line 5, resources/assets/front/css/components/_pagination.scss */
    .pagination {
      display: flex;
      justify-content: space-between; } }

/* line 14, resources/assets/front/css/components/_pagination.scss */
.pagination__link {
  display: block !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 0.5rem; }
  @media (max-width: 767px) {
    /* line 14, resources/assets/front/css/components/_pagination.scss */
    .pagination__link {
      border: none; } }

/* line 1, resources/assets/front/css/components/_slideshow.scss */
.gallery--slideshow {
  width: 100%;
  height: 100vh; }
  /* line 7, resources/assets/front/css/components/_slideshow.scss */
  .gallery--slideshow .flickity-prev-next-button {
    width: 24px;
    padding: 0;
    top: 62px;
    bottom: 124px;
    border-radius: 0;
    height: calc(100vh - 186px);
    transform: none;
    display: none; }
    @media (min-width: 768px) {
      /* line 7, resources/assets/front/css/components/_slideshow.scss */
      .gallery--slideshow .flickity-prev-next-button {
        display: block; } }
  /* line 22, resources/assets/front/css/components/_slideshow.scss */
  .gallery--slideshow .flickity-button:disabled {
    display: none; }
  /* line 26, resources/assets/front/css/components/_slideshow.scss */
  .gallery--slideshow .flickity-prev-next-button.previous {
    left: 0.75rem; }
  /* line 30, resources/assets/front/css/components/_slideshow.scss */
  .gallery--slideshow .flickity-prev-next-button.next {
    right: 0.75rem; }

/* line 35, resources/assets/front/css/components/_slideshow.scss */
.gallery__item {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 31px;
  padding-bottom: 93px;
  padding-left: 1rem;
  padding-right: 1rem; }
  @media (min-width: 768px) {
    /* line 35, resources/assets/front/css/components/_slideshow.scss */
    .gallery__item {
      padding-left: 62px;
      padding-right: 62px; } }
  /* line 51, resources/assets/front/css/components/_slideshow.scss */
  .gallery__item img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-width: 1400px;
    opacity: 0;
    transition: 1s opacity; }
  /* line 61, resources/assets/front/css/components/_slideshow.scss */
  .gallery__item.is-selected img {
    opacity: 1; }

/* line 67, resources/assets/front/css/components/_slideshow.scss */
.gallery--hidden {
  display: none; }

/* line 3, resources/assets/front/css/templates/_index.scss */
.index--home .index__logo {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  height: 100vh; }

/* line 12, resources/assets/front/css/templates/_index.scss */
.index--home .index__item {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 31px;
  padding-bottom: 93px; }
  @media (min-width: 768px) {
    /* line 12, resources/assets/front/css/templates/_index.scss */
    .index--home .index__item {
      padding-left: 62px;
      padding-right: 62px; } }
  /* line 24, resources/assets/front/css/templates/_index.scss */
  .index--home .index__item__link {
    display: block;
    height: 100%; }
  /* line 29, resources/assets/front/css/templates/_index.scss */
  .index--home .index__item img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-width: 1200px; }

/* line 41, resources/assets/front/css/templates/_index.scss */
.index--text .index__inner {
  padding: 0 1rem;
  margin-top: 1rem;
  margin-bottom: 124px; }
  @media (min-width: 768px) {
    /* line 41, resources/assets/front/css/templates/_index.scss */
    .index--text .index__inner {
      width: calc(100vw / 1.5);
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto; } }

/* line 54, resources/assets/front/css/templates/_index.scss */
.index--text .index__title {
  display: none; }

/* line 60, resources/assets/front/css/templates/_index.scss */
.index--text .block_list:not(:first-child) {
  margin-top: 1rem; }

/* line 65, resources/assets/front/css/templates/_index.scss */
.index--text .block__title {
  border-top: 1px solid;
  padding-top: 0.5rem;
  margin-bottom: 0.5rem; }

/*------------------------------------*\
  index
\*------------------------------------*/
/* line 6, resources/assets/front/css/templates/_index-grid.scss */
.index--grid .index__title {
  display: none; }

/* line 9, resources/assets/front/css/templates/_index-grid.scss */
.index--grid .index__content {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 2.5rem; }
  /* line 14, resources/assets/front/css/templates/_index-grid.scss */
  .index--grid .index__content .block {
    width: calc(100%/4);
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 1.5rem; }
    @media (max-width: 1023px) {
      /* line 14, resources/assets/front/css/templates/_index-grid.scss */
      .index--grid .index__content .block {
        width: calc(100%/2); } }
  /* line 21, resources/assets/front/css/templates/_index-grid.scss */
  .index--grid .index__content .block__link {
    text-decoration: none; }
  @supports (object-fit: cover) {
    /* line 24, resources/assets/front/css/templates/_index-grid.scss */
    .index--grid .index__content .block__media {
      position: relative;
      position: relative; }
      /* line 13, resources/assets/front/css/utils/_mixins.scss */
      .index--grid .index__content .block__media:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: 66.66667%; }
      /* line 19, resources/assets/front/css/utils/_mixins.scss */
      .index--grid .index__content .block__media > .content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0; }
      /* line 28, resources/assets/front/css/templates/_index-grid.scss */
      .index--grid .index__content .block__media img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;
        left: 0;
        top: 0; } }
  /* line 38, resources/assets/front/css/templates/_index-grid.scss */
  .index--grid .index__content .block__text {
    padding: 1rem;
    text-align: center; }

/*------------------------------------*\
  detail
\*------------------------------------*/
/* line 24, resources/assets/front/css/templates/_detail.scss */
.body img {
  display: block;
  margin: 1.5rem auto;
  height: 215px;
  width: auto;
  max-width: 100%; }

/* line 37, resources/assets/front/css/templates/_detail.scss */
.detail__text {
  max-width: 750px; }

/* line 41, resources/assets/front/css/templates/_detail.scss */
.detail__title {
  display: none;
  margin-bottom: 1rem; }
  /* line 46, resources/assets/front/css/templates/_detail.scss */
  .detail__title + .publication-date {
    margin-top: -0.5rem;
    margin-bottom: 1rem; }

/* line 52, resources/assets/front/css/templates/_detail.scss */
.detail__subtitle {
  margin-bottom: 1rem; }
  /* line 56, resources/assets/front/css/templates/_detail.scss */
  .detail__title + .detail__subtitle {
    margin-top: -1rem; }

/* line 61, resources/assets/front/css/templates/_detail.scss */
.detail__title + .detail__intro {
  margin-top: -0.5rem; }

/* line 65, resources/assets/front/css/templates/_detail.scss */
.detail__time {
  margin-bottom: 1rem; }
  /* line 69, resources/assets/front/css/templates/_detail.scss */
  .detail__title + .detail__time {
    margin-top: -1rem; }

/* line 74, resources/assets/front/css/templates/_detail.scss */
.detail .gallery {
  margin-top: 2.5rem; }
  @media (max-width: 767px) {
    /* line 74, resources/assets/front/css/templates/_detail.scss */
    .detail .gallery {
      margin-top: 2.5rem; } }

/*------------------------------------*\
  #BASE-STYLES
\*------------------------------------*/
/* line 55, resources/assets/front/css/screen.scss */
html {
  font-size: 14px; }
  @media (min-width: 480px) {
    /* line 55, resources/assets/front/css/screen.scss */
    html {
      font-size: 16px; } }
  @media (min-width: 768px) {
    /* line 55, resources/assets/front/css/screen.scss */
    html {
      font-size: 18px; } }

/* line 67, resources/assets/front/css/screen.scss */
body {
  background: #ccc;
  color: #000000;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased; }
  /* line 74, resources/assets/front/css/screen.scss */
  body ::-moz-selection,
  body ::selection {
    background-color: rgba(0, 0, 0, 0.1);
    color: #000000; }

/* line 81, resources/assets/front/css/screen.scss */
li {
  list-style: none; }

/*------------------------------------*\
  #PRINT
\*------------------------------------*/
/*------------------------------------*\
  print style
\*------------------------------------*/
@media print {
  /* line 94, resources/assets/front/css/screen.scss */
  html {
    font-size: 14px; }
  @page {
    margin: 2cm 2.5cm; }
  /* line 10, resources/assets/front/css/utils/_print-style.scss */
  body {
    font-family: Georgia, Times, Times New Roman, serif;
    font-size: 16pt;
    line-height: 22pt;
    color: #000000; }
  /* line 16, resources/assets/front/css/utils/_print-style.scss */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol {
    font-family: Georgia, Times, Times New Roman, serif;
    font-style: normal;
    font-variant: normal;
    font-weight: 400; }
  /* line 30, resources/assets/front/css/utils/_print-style.scss */
  h1 {
    font-size: 32pt;
    line-height: 40pt; }
  /* line 34, resources/assets/front/css/utils/_print-style.scss */
  h2 {
    font-size: 24pt;
    line-height: 30pt; }
  /* line 38, resources/assets/front/css/utils/_print-style.scss */
  h3 {
    font-size: 20pt;
    line-height: 26pt; }
  /* line 42, resources/assets/front/css/utils/_print-style.scss */
  h4 {
    font-size: 14pt;
    line-height: 20pt; }
  /* line 46, resources/assets/front/css/utils/_print-style.scss */
  p,
  ul,
  ol,
  blockquote {
    font-size: 11pt;
    line-height: 16pt; }
  /* line 53, resources/assets/front/css/utils/_print-style.scss */
  blockquote {
    text-align: center; }
  /* line 56, resources/assets/front/css/utils/_print-style.scss */
  small {
    font-size: 9pt;
    line-height: 14pt; }
  /* line 60, resources/assets/front/css/utils/_print-style.scss */
  p {
    page-break-inside: avoid; }
  /* line 63, resources/assets/front/css/utils/_print-style.scss */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid; }
  /* line 71, resources/assets/front/css/utils/_print-style.scss */
  hr {
    border: 0;
    height: 1px;
    background-color: #000000;
    margin: .3cm 0; }
  /* line 77, resources/assets/front/css/utils/_print-style.scss */
  .article {
    width: 100%;
    height: auto !important;
    margin: 0;
    display: block;
    padding: 0;
    overflow: visible;
    box-shadow: none; }
  /* line 86, resources/assets/front/css/utils/_print-style.scss */
  .header,
  .footer,
  .section.print {
    display: none !important; }
  /* line 91, resources/assets/front/css/utils/_print-style.scss */
  body {
    background: #fff !important;
    color: #000; }
  /* line 96, resources/assets/front/css/utils/_print-style.scss */
  .body p {
    widows: 3;
    orphans: 3; }
  /* line 97, resources/assets/front/css/utils/_print-style.scss */
  .body a {
    page-break-inside: avoid; }
  /* line 98, resources/assets/front/css/utils/_print-style.scss */
  .body blockquote {
    page-break-inside: avoid; }
  /* line 99, resources/assets/front/css/utils/_print-style.scss */
  .body h1, .body h2, .body h3, .body h4, .body h5, .body h6 {
    page-break-after: avoid;
    page-break-inside: avoid; }
  /* line 100, resources/assets/front/css/utils/_print-style.scss */
  .body img {
    page-break-inside: avoid;
    page-break-after: avoid; }
  /* line 101, resources/assets/front/css/utils/_print-style.scss */
  .body table, .body pre {
    page-break-inside: avoid; }
  /* line 102, resources/assets/front/css/utils/_print-style.scss */
  .body ul, .body ol, .body dl {
    page-break-before: avoid; }
  /* line 103, resources/assets/front/css/utils/_print-style.scss */
  .body a:link, .body a:visited, .body a {
    background: transparent;
    color: #2C51DF;
    font-weight: bold;
    text-decoration: underline;
    text-align: left; }
  /* line 110, resources/assets/front/css/utils/_print-style.scss */
  .body a[href^=http]:after {
    content: " <" attr(href) "> "; }
  /* line 111, resources/assets/front/css/utils/_print-style.scss */
  .body article a[href^="#"]:after {
    content: ""; }
  /* line 112, resources/assets/front/css/utils/_print-style.scss */
  .body a:not(:local-link):after {
    content: " <" attr(href) "> "; } }
