/*------------------------------------*\
  header
\*------------------------------------*/

.header {
  height: 62px;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  border-top: 1px solid $black;
  background: $gray;

  .page-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
}

.main-index-projects {
  .header_main {
    background: #ccc;
  }
}

.header_main {


  .header__inner {
    justify-content: space-between;
  }

  .icon {
    justify-content: flex-start;
  }

  .secondary-nav {
    text-align: right;
    justify-content: flex-end;
  }
}

.header__inner {
  padding: $spacing-sm;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
}

a.show {
  display: block;
}

.show {
  display: block;
}

.show-flex {
  display: flex !important;
}

.header-details {
  z-index: 3;
  top: 0vh;
  border-top: none;
  background: none;

  .header__inner {
    justify-content: space-between;
  }
}


// ICONS
.icon {
  display: flex;
  align-items: center;
  max-height: 25px;
  height: 100%;
  cursor: pointer;
}


.icon__menu {
  width: 35px;
  cursor: pointer;
  height: 2px;
  background: $black;
  // transition: background 0s .3s;

  span {

    &:before,
    &:after {
      position: relative;
      display: block;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: $black;
      content: "";
    }

    &:after {
      bottom: -8px;
    }

    &:before {
      top: -10px;
    }
  }
}

.icon__cross {
  width: 35px;
  height: 35px;
  color: $white;

  span {
    display: block;
    position: relative;

    &:before,
    &:after {
      position: absolute;
      display: block;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: $black;
      color: $black;
      content: "";
      top: 15px;
    }

    &:after {
      transform: rotate(135deg);
    }

    &:before {
      transform: rotate(45deg);
    }
  }
}

.icon__info {
  width: 35px;
  height: 35px;
  color: $white;
  cursor: pointer;

  span {
    display: block;
    position: relative;

    &:before,
    &:after {
      position: absolute;
      display: block;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: $black;
      color: $black;
      content: "";
      top: 15px;
    }

    &:before {
      transform: rotate(90deg);
    }
  }
}


.icon__back_to_top {
  display: none;
}

.main-home+.header,
.main-project+.header {
  background: transparent;
}
